import {useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import getJWT from 'common/authentication/jwt';

export default function SettingsSubPage () {
	
	let location = useLocation();
	
	useEffect(() => {
		
	  const jwt = getJWT();
	  
	  if(location.pathname == "/admin/settings/reportcenter" )
	  {
	  	window.location.href = `/common/jwtcreatesession.php?token=${jwt}&login_redirect=reports`
	  }
	  else
	  {
		window.location.href = `/common/jwtcreatesession.php?token=${jwt}&login_redirect=settings`
	  }
		
		
	})
	
  return <Paper sx={{ p: 2, mr: 3 }}>Please Wait Redirecting</Paper>;
}
